import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {} from 'react-router-dom';
import "./app.css";
import axios from "axios";
import Menu from "./Menu";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "./EditorToolbar";



class EditPage extends Component {

    constructor() {
        super();
        this.state = {
            page: [],
            menu_name: '',
            content: '',
            user: localStorage.getItem('user-email')
        }
    }


    handleInputName = (e) => {    this.setState({
        menu_name: e.target.value,

    });  }

    handleHtml = (html) => {    this.setState({
        content: html,

    });  }

    getPage = async () => {
        this.setState({loader: true});
        const page = await axios.get("https://api.uszkodzone24.pl/api/page/" + this.props.match.params.id);
        this.setState({ menu_name: page.data.menu_name,content: page.data.content,loader: false});
    };


    componentDidMount() {
        this.getPage();
    }

    createPage = async (e) => {
        e.preventDefault();
        const res = await axios.post('https://api.uszkodzone24.pl/api/edit-page/'+ this.props.match.params.id, {
            menu_name: this.state.menu_name,
            content: this.state.content,


        }).then(() => {
            window.alert('Poprawnie zapisano stronę');
        })

    }

    render() {
        return <div>
        <Menu/>
        <div className="ui main container">
                <h2 className="ui image header">
                    <div className="content">
                        Dodaj stronę
                    </div>
                </h2>
                <form onSubmit={this.createPage} className="ui large form">
                    <div className="ui stacked secondary  segment">
                        <div className="field">
                            <div className="ui left icon input">
                                <input required type="text" name="menu_name" placeholder="Nazwa w menu" value={this.state.menu_name} onChange={this.handleInputName}/>
                            </div>
                        </div>
                        
                        <EditorToolbar />
                        <ReactQuill
                            theme="snow"
                            value={this.state.content}
                            onChange={this.handleHtml}
                            placeholder={"Treść strony..."}
                            modules={modules}
                            formats={formats}
                        />
                        <button className="ui fluid large teal submit button" style={{backgroundColor: '#EC4137'}} >Zapisz stronę</button>
                    </div>

                    <div className="ui error message"></div>

                </form>
            </div>
        </div>

    }
}

export default EditPage;



import React, { Component } from "react";
import axios from "axios";



class Alert extends Component {

    toggleAlert = async (id,activeState) => {
        const res = await axios.get('https://api.uszkodzone24.pl/api/toggle-alert/'+id+'/'+activeState);
        window.location.reload(false);

    }

    render() {
        const { id,mark,start_year,end_year,is_active } = this.props.alert; 
        return (
            <tr>

                <td>{mark}</td>
                <td>{start_year}</td>
                <td>{end_year}</td>
                {is_active ? (

                    <td><button className="ui button" onClick={() => this.toggleAlert(id,0)}>Deaktywuj</button></td>
                ) : (
                    <td><button className="ui button" onClick={() => this.toggleAlert(id,1)}>Aktywuj</button></td>
                )
                }
            </tr>
        );
    }

}

export default Alert;
import axios from "axios";
import React, { Component } from "react";
import Menu from "./Menu";

class Users extends Component {
    
    
    state = {
      users: [],
      email: '',
      phone: '',
      name: '',
      search: '',
      user: localStorage.getItem('user-email')
      }


    updateSearch(event) {
        this.setState({search: event.target.value.toLowerCase().substr(0,20)});
    }
    getUsers = async () => {
      const users = await axios.get("https://api.uszkodzone24.pl/api/users");
      this.setState({ users: users.data, 
          phone: users.data[0].phone,
          name: users.data[0].name,
          email: users.data[0].email
      }) }
     componentDidMount() {
        this.getUsers();
    }


    render() {
      
      let filteredUsers = this.state.users.filter(
            (usersS) =>{
                return usersS.email.indexOf(this.state.search) !== -1;
            }
        );
        
       return ( 
        
        <div>
            
                <div className="ui main container" style={{marginTop: '30px'}}>
                <div className="row justify-content-xl-end">
                {/* <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-10px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="#"><img src={'fb-icon.png'} className={'img-fluid'} alt={'Ikona FB'}/><span style={{paddingLeft: '10px'}}>Polub nas na Facebooku</span></a></div> */}
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-20px"}}><a href="#" style={{textDecoration: 'none', color: '#000', fontWeight: '600', marginTop: '6px'}}><span style={{paddingRight: '10px'}}>Kontakt:</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-40px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="tel:+48731057255"><img src={'phone-icon.png'} className={'img-fluid'} alt={'Ikona telefonu'}/><span style={{paddingLeft: '10px'}}>0048 731 057 255 (9:00-16:00)</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end"><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="mailto:uszkodzone.info@gmail.com"><img src={'mail-icon.png'} className={'img-fluid'} alt={'Ikona maila'}/><span style={{paddingLeft: '10px'}}>uszkodzone.info@gmail.com</span></a></div>
                </div>
                <Menu />
                 <div className="data">
                    <div className="ui input">
                        <input type="text" className={"search-custom"} value={this.state.search} onChange={this.updateSearch.bind(this)} placeholder="Wyszukaj"/>
                    </div>
                    <div>
                        </div>
                </div>
                <div style={{marginTop: '30px'}}>
                                        <table>
                                          <tr>
                                            <th>Email:</th>
                                            <th>Telefon:</th>
                                            </tr>
                                            
                        {filteredUsers.map((user) => {
                                        return (
                                          <tr key={user.id} style={{borderBottom: '2px solid #000'}}>
                                            <td style={{paddingRight: "20px"}}>
                                            {user.email}
                                            </td>
                                            <td style={{color: 'red', fontWeight: '600'}}>
                                              {user.phone}
                                            </td>
                                            </tr>
                                            
                                        )
                                })}
                                </table>
                                </div>
                </div>
                
               </div>
            
            );
    }
}


export default Users;

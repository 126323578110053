import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {} from 'react-router-dom';
import "./app.css";
import axios from "axios";



class UserProfile extends Component {

    constructor() {
        super();
        this.state = {
            name: '',
            phone: '',
            user: localStorage.getItem('user-email'),
            url: "https://api.uszkodzone24.pl/api/user-info/"+localStorage.getItem('user-email'),
        }
    }


    getAuthUserDetails = async () => {
      const user = await axios.get(this.state.url);
      this.setState({ name: user.data.name, phone: user.data.phone});
    };


    handleInputName = (e) => {    this.setState({
        name: e.target.value,

    });  }

    handleInputPhone = (e) => {    this.setState({
        phone: e.target.value,
    }); }

    editUser = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://api.uszkodzone24.pl/api/edit-user', {
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.user

        }).then(() => {
            window.alert('Poprawnie zedytowano użytkownika');
            window.location.replace("https://uszkodzone24.pl/lista-aukcji");
        })

    }

    componentDidMount() {
        this.getAuthUserDetails();
    }

    render() {
        return <div>
        <div className="ui middle aligned center aligned grid">
            <div className="column">
                <h2 className="ui image header">
                    <div className="content">
                        <img src={'https://uszkodzone24.pl/logo.png'} className={'img-fluid'} alt={'Uszkodzone logo'}/>
                        Edytuj swoje dane
                    </div>
                </h2>
                <form onSubmit={this.editUser} className="ui large form">
                    <div className="ui stacked secondary  segment">
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="text" name="name" placeholder="Nazwa" value={this.state.name} onChange={this.handleInputName}/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="text" name="phone" placeholder="Polski telefon komórkowy (9 cyfr)" value={this.state.phone} onChange={this.handleInputPhone}/>
                            </div>
                        </div>
                        <button className="ui fluid large teal submit button" style={{backgroundColor: '#EC4137',marginTop: '20px'}} >Zapisz dane użytkownika</button>
                    </div>

                    <div className="ui error message"></div>

                </form>
            </div>
        </div>
        </div>

    }
}

export default UserProfile;
import React, { Component } from "react";
import axios from "axios";
import AuctionDetailsForQuests from "./AuctionDetailsForQuests";
import Loader from "./Loader";
import MenuQuests from './MenuQuests';
import "./app.css";


class AuctionSingleForQuests extends Component {
    state = {
        auction: [],
        images: '',
        titleSliced: '',
        end_atReplaced: '',
        loader: false,
        url: "https://api.uszkodzone24.pl/api/auction/" + this.props.match.params.id,
    }
    getAuction = async () => {
        this.setState({loader: true});
        const auction = await axios.get(this.state.url);
        this.setState({ auction: auction.data,images: auction.data.images_content, loader: false, titleSliced: auction.data.title, end_atReplaced: auction.data.end_at});
    };


    componentDidMount() {
        this.getAuction();
    }

    render() {

        return <div>
           
            <div className="ui main container">
            <div className="row justify-content-xl-end">
            <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-20px"}}><a href="#" style={{textDecoration: 'none', color: '#000', fontWeight: '600',marginTop: '6px'}}><span style={{paddingRight: '10px'}}>Kontakt:</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-40px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="tel:+48731057255"><img src={'http://uszkodzone24.pl/phone-icon.png'} className={'img-fluid'} alt={'Ikona telefonu'}/><span style={{paddingLeft: '10px'}}>0048 731 057 255 (9:00-16:00)</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end"><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="mailto:uszkodzone.info@gmail.com"><img src={'http://uszkodzone24.pl/mail-icon.png'} className={'img-fluid'} alt={'Ikona maila'}/><span style={{paddingLeft: '10px'}}>uszkodzone.info@gmail.com</span></a></div>
           </div>
            <MenuQuests />
            <div style={{ maxWidth: '380px' }} className={'icons-div'}>
  <a href={'/osobowe'}><img src={'https://uszkodzone24.pl/car.png'} style={{width: '40px'}} className={'img-fluid icon'} alt={'Osobowe'}/></a>
  <a href={'/motocykle'}><img src={'https://uszkodzone24.pl/motocycle.png'} style={{width: '60px',paddingLeft: '20px'}} className={'img-fluid icon'} alt={'Motocykle'}/></a>
  <a href={'/dostawcze'}><img src={'https://uszkodzone24.pl/dostawcze.png'} style={{width: '60px',paddingLeft: '20px'}} className={'img-fluid icon'} alt={'Dostawcze'}/></a>
  <a href={'/ciezarowe'}><img src={'https://uszkodzone24.pl/ciezarowe.png'} style={{width: '90px',paddingLeft: '20px'}} className={'img-fluid icon ciez'} alt={'Ciężarowe'}/></a>
  {/* <img src={'https://uszkodzone24.pl/przyczepa.png'} style={{width: '55px',paddingLeft: '20px',marginBottom: '5px'}} className={'img-fluid icon'} alt={'Przyczepy'}/> */}
  <a href={'/rolnicze'}><img src={'https://uszkodzone24.pl/rolnicze.png'} style={{width: '46px',paddingLeft: '20px'}} className={'img-fluid icon rol'} alt={'Rolnicze'}/></a>
  <div style={{float: 'right', marginTop: '18px'}} className={'other-div'}>
  <a href={'/inne'}><p style={{paddingRight: '20px',display: 'inline', fontWeight: '600',fontSize: '24px',color: '#000000'}}>Inne</p></a>
  </div>
  </div>
                {
                    this.state.loader ? <Loader /> : ""
                }
                <AuctionDetailsForQuests auction={this.state.auction} images={this.state.images} titleSliced={this.state.titleSliced.slice(0, -14)} end_atReplaced={this.state.end_atReplaced.replace('.000000Z','').replace('T',' ')}/>
            </div>
        </div>
    }
}

export default AuctionSingleForQuests;
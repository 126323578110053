import React, { Component } from "react";
import axios from "axios";
import AuctionListForQuests from "./AuctionListForQuests";
import Loader from "./Loader";
import MenuQuests from './MenuQuests';
import "./app.css";


class VanCarsForQuests extends Component {
    state = {
        favorites: [],
        auctions: [],
        loader: false,
        url: "https://api.uszkodzone24.pl/api/dostawcze",
    }
    getAuctions = async () => {
        this.setState({loader: true});
      const auctions = await axios.get(this.state.url);
      this.setState({ auctions: auctions.data, loader: false});
    };

    componentDidMount() {
        this.getAuctions();
    }


    render() {

        return <div>
            
                <div className="ui main container" style={{marginTop: '30px'}}>
                <div className="row justify-content-xl-end">
                {/* <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-10px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="#"><img src={'fb-icon.png'} className={'img-fluid'} alt={'Ikona FB'}/><span style={{paddingLeft: '10px'}}>Polub nas na Facebooku</span></a></div> */}
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-20px"}}><a href="#" style={{textDecoration: 'none', color: '#000', fontWeight: '600', marginTop: '6px'}}><span style={{paddingRight: '10px'}}>Kontakt:</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-40px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="tel:+48731057255"><img src={'phone-icon.png'} className={'img-fluid'} alt={'Ikona telefonu'}/><span style={{paddingLeft: '10px'}}>0048 731 057 255 (9:00-16:00)</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end"><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="mailto:uszkodzone.info@gmail.com"><img src={'mail-icon.png'} className={'img-fluid'} alt={'Ikona maila'}/><span style={{paddingLeft: '10px'}}>uszkodzone.info@gmail.com</span></a></div>
                </div>
                <MenuQuests />
                <div style={{ maxWidth: '380px' }} className={'icons-div'}>
  <a href={'/osobowe'}><img src={'https://uszkodzone24.pl/car.png'} style={{width: '40px'}} className={'img-fluid icon'} alt={'Osobowe'}/></a>
  <a href={'/motocykle'}><img src={'https://uszkodzone24.pl/motocycle.png'} style={{width: '60px',paddingLeft: '20px'}} className={'img-fluid icon'} alt={'Motocykle'}/></a>
  <a href={'/dostawcze'}><img src={'https://uszkodzone24.pl/dostawcze-red.png'} style={{width: '60px',paddingLeft: '20px'}} className={'img-fluid icon'} alt={'Dostawcze'}/></a>
  <a href={'/ciezarowe'}><img src={'https://uszkodzone24.pl/ciezarowe.png'} style={{width: '90px',paddingLeft: '20px'}} className={'img-fluid icon ciez'} alt={'Ciężarowe'}/></a>
  {/* <img src={'https://uszkodzone24.pl/przyczepa.png'} style={{width: '55px',paddingLeft: '20px',marginBottom: '5px'}} className={'img-fluid icon'} alt={'Przyczepy'}/> */}
  <a href={'/rolnicze'}><img src={'https://uszkodzone24.pl/rolnicze.png'} style={{width: '46px',paddingLeft: '20px'}} className={'img-fluid icon rol'} alt={'Rolnicze'}/></a>
  <div style={{float: 'right', marginTop: '18px'}} className={'other-div'}>
  <a href={'/inne'}><p style={{paddingRight: '20px',display: 'inline', fontWeight: '600',fontSize: '24px',color: '#000000'}}>Inne</p></a>
  </div>
  </div>
                    {
                        this.state.loader ? <Loader /> : ""
                    }
                    <h1 style={{color: '#EC4137'}}>Dostawcze</h1>
                    <AuctionListForQuests auctions={this.state.auctions}/>
                </div>
            </div>
    }
}

export default VanCarsForQuests;
import axios from "axios";
import React, { Component } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import "./app.css";


class Menu extends Component {
  state = {
    pages: [],
    loader: false,
    url: "https://api.uszkodzone24.pl/api/pages",
    user: localStorage.getItem('user-email'),
}
getPages = async () => {
    this.setState({loader: true});
  const pages = await axios.get(this.state.url);
  this.setState({ pages: pages.data, loader: false});
};

componentDidMount() {
  this.getPages();
}

    render() {

        return (
          <div>
            <div>
                  <nav className="navbar navbar-expand-lg" style={{backgroundColor: 'white'}}>
      <div className="container-fluid">
      <a className="navbar-brand" href="/"> <img src={'https://uszkodzone24.pl/logo.png'} className={'img-fluid'} alt={'Uszkodzone logo'} style={{paddingBottom: '30px', marginLeft: '-20px'}}/> </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        {(this.state.user === "norbert.skrzypczynski@wp.pl") ? (
            <Dropdown> 
            <Dropdown.Toggle size="sm" variant="danger"> 
              Zarządzanie 
            </Dropdown.Toggle> 
            <Dropdown.Menu> 
              <Dropdown.Item href="/administracja-snfhfhmngfdsbhjjhgfgfffdddxcvbbb"> 
                Administracja
              </Dropdown.Item> 
              <Dropdown.Item href="/dodaj-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb"> 
              Dodaj użytkownika
              </Dropdown.Item> 
              <Dropdown.Item href="/zablokuj-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb"> 
              Zablokuj użytkownika
              </Dropdown.Item>
              <Dropdown.Item href="/edytuj-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb"> 
              Edytuj użytkownika
              </Dropdown.Item> 
              <Dropdown.Item href="/uzytkownicy"> 
              Lista użytkowników
              </Dropdown.Item>
              <Dropdown.Item href="/strony"> 
              Dodatkowe strony
              </Dropdown.Item>  
            </Dropdown.Menu> 
          </Dropdown> 
             ) : (null)
          }    
          <li className="nav-item">
            <a className="nav-link" href="/lista-aukcji">Lista aukcji</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/ulubione">Ulubione</a>
          </li>                    
                    <li className="nav-item">
            <a className="nav-link" href="/moje-oferty">Moje oferty</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/moje-alerty">Powiadomienia</a>
          </li>
          {this.state.pages.map((page) => { return (
                  <li key={page.id} className="nav-item">
                <a className="nav-link" href={'/strona/'+page.id}>{page.menu_name}</a>
                </li>
                  );
            })}
            <li className="nav-item">
            <a className="nav-link" href="/profil">Profil</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/">Wyloguj</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://iaai.com">Licytacje z USA</a>
          </li>       
        </ul>
      </div>
    </div>
  </nav>
  </div>
  </div> )
    }
}

export default Menu;
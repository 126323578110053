import React, { Component } from "react";
import axios from "axios";
import AlertsList from "./AlertsList";
import Loader from "./Loader";
import "./app.css";
import Menu from "./Menu";


class Alerts extends Component {

    constructor() {
        super();
        this.state = {
            searchMark: '',
            searchStartDate: '',
            searchEndDate: '',
            alerts: [],
            loader: false,
            url: "https://api.uszkodzone24.pl/api/myalerts/"+localStorage.getItem('user-email'),
            user: localStorage.getItem('user-email')
        }
    }

    getAlerts = async () => {
        this.setState({loader: true});
        const alerts = await axios.get(this.state.url);
        this.setState({ alerts: alerts.data, loader: false});
    };

    addAlert = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://api.uszkodzone24.pl/api/add-alert', {
            email: this.state.user,
            mark: this.state.searchMark,
            model: this.state.searchMark,
            start_year: this.state.searchStartDate,
            end_year: this.state.searchEndDate

        }).then(() => {
            window.alert('Poprawnie ustawiono powiadomienie');
            window.location.reload(false);
        })

    }

    updateSearchMark(event) {
        this.setState({searchMark: event.target.value.toLowerCase().substr(0,20)});
    }

    updateSearchStartDate(event) {
        this.setState({searchStartDate: event.target.value.toLowerCase().substr(0,20)});
    }

    updateSearchEndDate(event) {
        this.setState({searchEndDate: event.target.value.toLowerCase().substr(0,20)});
    }
    componentDidMount() {
        this.getAlerts();
    }
    
    render() {

        return <div>
            
                <div className="ui main container" style={{marginTop: '30px'}}>
                <div className="row justify-content-xl-end">
                {/* <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-10px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="#"><img src={'fb-icon.png'} className={'img-fluid'} alt={'Ikona FB'}/><span style={{paddingLeft: '10px'}}>Polub nas na Facebooku</span></a></div> */}
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-20px"}}><a href="#" style={{textDecoration: 'none', color: '#000', fontWeight: '600', marginTop: '6px'}}><span style={{paddingRight: '10px'}}>Kontakt:</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-40px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="tel:+48731057255"><img src={'phone-icon.png'} className={'img-fluid'} alt={'Ikona telefonu'}/><span style={{paddingLeft: '10px'}}>0048 731 057 255 (9:00-16:00)</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end"><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="mailto:uszkodzone.info@gmail.com"><img src={'mail-icon.png'} className={'img-fluid'} alt={'Ikona maila'}/><span style={{paddingLeft: '10px'}}>uszkodzone.info@gmail.com</span></a></div>
                </div>
                <Menu />
                <div style={{ maxWidth: '380px' }} className={'icons-div'}>
  <a href={'/lista-osobowe'}><img src={'https://uszkodzone24.pl/car.png'} style={{width: '40px'}} className={'img-fluid icon'} alt={'Osobowe'}/></a>
  <a href={'/lista-motocykle'}><img src={'https://uszkodzone24.pl/motocycle.png'} style={{width: '60px',paddingLeft: '20px'}} className={'img-fluid icon'} alt={'Motocykle'}/></a>
  <a href={'/lista-dostawcze'}><img src={'https://uszkodzone24.pl/dostawcze.png'} style={{width: '60px',paddingLeft: '20px'}} className={'img-fluid icon'} alt={'Dostawcze'}/></a>
  <a href={'/lista-ciezarowe'}><img src={'https://uszkodzone24.pl/ciezarowe.png'} style={{width: '90px',paddingLeft: '20px'}} className={'img-fluid icon ciez'} alt={'Ciężarowe'}/></a>
  {/* <img src={'https://uszkodzone24.pl/przyczepa.png'} style={{width: '55px',paddingLeft: '20px',marginBottom: '5px'}} className={'img-fluid icon'} alt={'Przyczepy'}/> */}
  <a href={'/lista-rolnicze'}><img src={'https://uszkodzone24.pl/rolnicze.png'} style={{width: '46px',paddingLeft: '20px'}} className={'img-fluid icon rol'} alt={'Rolnicze'}/></a>
  <div style={{float: 'right', marginTop: '18px'}} className={'other-div'}>
  <a href={'/lista-inne'}><p style={{paddingRight: '20px',display: 'inline', fontWeight: '600',fontSize: '24px',color: '#00000'}}>Inne</p></a>
  </div>
  </div>
                    {
                        this.state.loader ? <Loader /> : ""
                    }
                    <AlertsList alerts={this.state.alerts}/>
                    <h5>Marka:</h5>
              <input type="text" className={"search-custom"} value={this.state.searchMark} onChange={this.updateSearchMark.bind(this)} placeholder="Marka"/>
              <h5>Pierwsza rejestracja:</h5>
              <input type="text" className={"search-custom"} value={this.state.searchStartDate} onChange={this.updateSearchStartDate.bind(this)} placeholder="Od roku"/>
              <input type="text" className={"search-custom margin-left"} value={this.state.searchEndDate} onChange={this.updateSearchEndDate.bind(this)} placeholder="Do roku"/>
              <button className="ui fluid large teal submit button" style={{backgroundColor: '#EC4137',marginTop: '20px'}} onClick={this.addAlert}>Powiadom mnie o dostępności</button>
                </div>
            </div>
    }
}

export default Alerts;
import axios from "axios";
import React, { Component } from "react";
import Auction from "./Auction";

class AuctionList extends Component {
    constructor() {
        super();
        this.state = {
            searchMark: '',
            searchStartDate: '',
            searchEndDate: '',
            user: localStorage.getItem('user-email')
        }
    }

    addAlert = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://api.uszkodzone24.pl/api/add-alert', {
            email: this.state.user,
            mark: this.state.searchMark,
            model: this.state.searchMark,
            start_year: this.state.searchStartDate,
            end_year: this.state.searchEndDate

        }).then(() => {
            window.alert('Poprawnie ustawiono powiadomienie');
        })

    }

    updateSearchMark(event) {
        this.setState({searchMark: event.target.value.toLowerCase().substr(0,20)});
    }

    updateSearchStartDate(event) {
        this.setState({searchStartDate: event.target.value.toLowerCase().substr(0,20)});
    }

    updateSearchEndDate(event) {
        this.setState({searchEndDate: event.target.value.toLowerCase().substr(0,20)});
    }
    render() {
        const _ = require('lodash');
        const auctions = this.props.auctions;
        const favorites = this.props.favorites;
        //console.log(favorites);
        let filteredAuctions = auctions.filter(
            (auction) =>{
                return auction.title.toUpperCase().indexOf(this.state.searchMark) !== -1 || auction.title.toLowerCase().indexOf(this.state.searchMark) !== -1 || auction.title.indexOf(this.state.searchMark) !== -1 ||
                auction.insurance.toUpperCase().indexOf(this.state.searchMark) !== -1 || auction.insurance.toLowerCase().indexOf(this.state.searchMark) !== -1 || auction.insurance.indexOf(this.state.searchMark) !== -1;
            }
        ).filter(
                (auction) =>{
                    return (parseInt(auction.register_date) >= parseInt(this.state.searchStartDate)) || this.state.searchStartDate == '';
                })
                .filter(
                    (auction) =>{
                        return (parseInt(auction.register_date) <= parseInt(this.state.searchEndDate)) || this.state.searchEndDate == '';
                    });
                      
        return (
          <div className="data">
              <input type="text" className={"search-custom"} value={this.state.searchMark} onChange={this.updateSearchMark.bind(this)} placeholder="Szukaj"/>
              <input type="text" className={"search-custom-year margin-left"} value={this.state.searchStartDate} onChange={this.updateSearchStartDate.bind(this)} placeholder="Od roku"/>
              <input type="text" className={"search-custom-year margin-left"} value={this.state.searchEndDate} onChange={this.updateSearchEndDate.bind(this)} placeholder="Do roku"/>
              {_.isEmpty(filteredAuctions) ? (<div className="data">
              <h5>Wygląda na to, że nie mamy tego czego szukasz, ale możemy Cię powiadomić o dostępności.</h5>
              <button className="ui fluid large teal submit button" style={{backgroundColor: '#EC4137',marginTop: '20px'}} onClick={this.addAlert}>Powiadom mnie o dostępności</button>
              </div>) : (null)}
                  <table className="ui celled table">
                  <thead>
                  <tr>
                      <th>Ulubione</th>
                      <th style={{width: "148px"}}></th>
                      <th>Ubezpieczalnia</th>
                      <th>Rok pierwszej rejestracji</th>
                      <th>Tytuł</th>
                      <th>Koniec aukcji</th>
                      {(this.state.user === "norbert.skrzypczynski@wp.pl") ? (
                    <th>Zmiana typu</th>) : (null)
                    }
                      
                  </tr>
                  </thead>

                  <tbody>
                  {filteredAuctions.map((auctions) => {
                      return (
                              <Auction favorites={favorites} auctions={auctions} key={auctions.id}/>

                          )
                      })}
                  </tbody>
              </table>
          </div>
        );
    }
}

export default AuctionList;
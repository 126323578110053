import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {} from 'react-router-dom';
import "./app.css";
import axios from "axios";
import Menu from "./Menu";



class BlockUser extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            user: localStorage.getItem('user-email')
        }
    }

    handleInputEmail = (e) => {    this.setState({
        email: e.target.value,
    }); }


    blockUser = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://api.uszkodzone24.pl/api/block', {
            email: this.state.email

        }).then(() => {
            window.alert('Poprawnie zablokowano użytkownika');
        })

    }

    render() {
        return <div>
            <Menu/>
            <div className="ui middle aligned center aligned grid">
                <div className="column">
                    <h2 className="ui image header">
                        <div className="content">
                            Zablokuj użytkownika
                        </div>
                    </h2>
                    <form onSubmit={this.blockUser} className="ui large form">
                        <div className="ui stacked secondary  segment">
                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="user icon"></i>
                                    <input required type="text" name="email" placeholder="E-mail" value={this.state.email} onChange={this.handleInputEmail}/>
                                </div>
                            </div>

                            <button className="ui fluid large teal submit button" style={{backgroundColor: '#fcb900'}} >Zablokuj użytkownika</button>
                        </div>

                        <div className="ui error message"></div>

                    </form>
                </div>
            </div>
        </div>

    }
}

export default BlockUser;
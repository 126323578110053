import React, { Component } from "react";
import axios from "axios";
import Loader from "./Loader";
import "./app.css";
import Menu from "./Menu";


class PageList extends Component {
    
    state = {
        pages: [],
        loader: false,
        url: "https://api.uszkodzone24.pl/api/pages",
        user: localStorage.getItem('user-email'),
    }
    getPages = async () => {
        this.setState({loader: true});
      const pages = await axios.get(this.state.url);
      this.setState({ pages: pages.data, loader: false});
    };

    componentDidMount() {
        this.getPages();
    }

    dellPage = async (id) => {
        const res = await axios.get('https://api.uszkodzone24.pl/api/delete-page/'+id);
        window.location.reload(false);

    }

    render() {
        return (
            <div>
            <Menu/>
                <div className="ui main container">

          <div className="data">
          <a href="/dodaj-strone"><button class="ui button">Dodaj stronę</button></a>
                  <table className="ui celled table">
                  <thead>
                  <tr>
                      <th>Nazwa strony</th>
                      <th>Edytuj</th>
                      <th>Usuń</th>
                  </tr>
                  </thead>

                  <tbody>
                  {this.state.pages.map((page) => { return (
                  <tr key={page.id}>
                <td>{page.menu_name}</td>
                <td><a href={'/edytuj-strone/'+page.id}>Edytuj</a></td>
                {(this.state.user === "norbert.skrzypczynski@wp.pl") ? (
                        <td><button className="primary button" onClick={() => this.dellPage(page.id)}>Usuń</button></td>
                       ) : (null)
                    }
            </tr>
                  );
            })}
                  </tbody>
              </table>
          </div>
          </div>
            </div>
        );
    }

    }


export default PageList;
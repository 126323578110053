import React, { Component } from "react";
import axios from "axios";
import Loader from "./Loader";
import "./app.css";
import Menu from "./Menu";
import MenuQuests from "./MenuQuests";


class Page extends Component {
    state = {
        page: [],
        loader: false,
        url: "https://api.uszkodzone24.pl/api/page/" + this.props.match.params.id,
        user: localStorage.getItem('user-email')
    }
    getPage = async () => {
        this.setState({loader: true});
        const page = await axios.get(this.state.url);
        this.setState({ page: page.data,loader: false});
    };


    componentDidMount() {
        this.getPage();
    }

    renderElement(){
        if(this.state.user == null )
           return <MenuQuests/>;
        return <Menu/>;
     }

    render() {
        return <div>
            <div className="ui main container" style={{marginTop: '30px'}}>
                <div className="row justify-content-xl-end">
                {/* <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-10px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="#"><img src={'fb-icon.png'} className={'img-fluid'} alt={'Ikona FB'}/><span style={{paddingLeft: '10px'}}>Polub nas na Facebooku</span></a></div> */}
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-20px"}}><a href="#" style={{textDecoration: 'none', color: '#000', fontWeight: '600', marginTop: '6px'}}><span style={{paddingRight: '10px'}}>Kontakt:</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-40px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="tel:+48731057255"><img src={'https://uszkodzone24.pl/phone-icon.png'} className={'img-fluid'} alt={'Ikona telefonu'}/><span style={{paddingLeft: '10px'}}>0048 731 057 255 (9:00-16:00)</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end"><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="mailto:uszkodzone.info@gmail.com"><img src={'https://uszkodzone24.pl/mail-icon.png'} className={'img-fluid'} alt={'Ikona maila'}/><span style={{paddingLeft: '10px'}}>uszkodzone.info@gmail.com</span></a></div>
                </div>
                { this.renderElement() }
                {
                    this.state.loader ? <Loader /> : ""
                }
                <div dangerouslySetInnerHTML={{__html: this.state.page.content}}/>
            </div>
        </div>
    }
}

export default Page;
import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {} from 'react-router-dom';
import "./app.css";
import axios from "axios";
import Menu from "./Menu";



class AddUser extends Component {

    constructor() {
        super();
        this.state = {
            password: '',
            email: '',
            name: '',
            user: localStorage.getItem('user-email')
        }
    }


    handleInputName = (e) => {    this.setState({
        name: e.target.value,

    });  }

    handleInputEmail = (e) => {    this.setState({
        email: e.target.value,
    }); }

    handleInputPass = (e) => {    this.setState({
        password: e.target.value,
    }); }

    createUser = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://api.uszkodzone24.pl/api/register', {
            password: this.state.password,
            name: this.state.name,
            email: this.state.email

        }).then(() => {
            window.alert('Poprawnie dodano użytkownika');
        })

    }

    render() {
        return <div>
            
            <Menu/>
        <div className="ui middle aligned center aligned grid">
            <div className="column">
                <h2 className="ui image header">
                    <div className="content">
                        Dodaj użytkownika
                    </div>
                </h2>
                <form onSubmit={this.createUser} className="ui large form">
                    <div className="ui stacked secondary  segment">
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="text" name="name" placeholder="Nazwa" value={this.state.name} onChange={this.handleInputName}/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="text" name="email" placeholder="E-mail" value={this.state.email} onChange={this.handleInputEmail}/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="password" name="password" placeholder="Hasło" value={this.state.password} onChange={this.handleInputPass}/>
                            </div>
                        </div>
                        <button className="ui fluid large teal submit button" style={{backgroundColor: '#fcb900'}} >Dodaj użytkownika</button>
                    </div>

                    <div className="ui error message"></div>

                </form>
            </div>
        </div>
        </div>

    }
}

export default AddUser;
import React, { Component } from "react";
// import axios from "axios";
import axios from "axios";
import { } from 'react-router-dom';
import Menu from "./Menu";
import "./app.css";



class EditUser extends Component {

    constructor() {
        super();
        this.state = {
            users: [],
            email: '',
            phone: '',
            name: '',
            user: localStorage.getItem('user-email')
        }
    }

    handleInputEmail = (e) => {    this.setState({
        email: e.target.value,
        phone: this.state.users.filter(user => user.email.includes(e.target.value)).map(user => (
            user.phone
          )),
          name: this.state.users.filter(user => user.email.includes(e.target.value)).map(user => (
            user.name
          ))
    }) }

    handleInputPhone = (e) => {    this.setState({
        phone: e.target.value,
    }) }

    handleInputName = (e) => {    this.setState({
        name: e.target.value,
    }) }

    getUsers = async () => {
        const users = await axios.get("https://api.uszkodzone24.pl/api/users");
        this.setState({ users: users.data, 
            phone: users.data[0].phone,
            name: users.data[0].name,
            email: users.data[0].email
        }) }
       componentDidMount() {
          this.getUsers();
      }


    editUser = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://api.uszkodzone24.pl/api/uedit', {
            email: this.state.email,
            phone: this.state.phone,
            name: this.state.name

        }).then(() => {
            window.alert('Poprawnie zapisano użytkownika');
            window.location.replace("/lista-aukcji");
        })

    }

    render() {
        return <div>
            <Menu/>
            <div className="ui middle aligned center aligned grid">
                <div className="column">
                    <h2 className="ui image header">
                        <div className="content">
                            Edytuj użytkownika
                        </div>
                    </h2>
                    <form onSubmit={this.editUser} className="ui large form">
                        <div className="ui stacked secondary  segment">
                            <div className="field">
                                <div className="input">
                                    <select required name="email" placeholder="E-mail" value={this.state.email} onChange={this.handleInputEmail}>
                                    {this.state.users.map((user,i) => {
                                        return (
                                        <option value={user.email}>{user.email}</option>
                                        )
                                })}
                                </select>
                                </div>
                            </div>
                                <div className="field">
                                <div className="input">
                                <input required type="text" name="name" placeholder="Nazwa" defaultValue={this.state.name !== '' ? this.state.name : ''} onChange={this.handleInputName}/>
                                </div>
                                </div>
                                <div className="field">
                                <div className="input">
                                <input required type="text" name="phone" placeholder="Telefon" defaultValue={this.state.phone !== '' ? this.state.phone : ''} onChange={this.handleInputPhone}/>
                                </div>
                                </div>
                              

                            <button className="ui fluid large teal submit button" style={{backgroundColor: '#fcb900'}} >Zapisz użytkownika</button>
                        </div>

                        <div className="ui error message"></div>

                    </form>
                </div>
            </div>
        </div>

    }
}

export default EditUser;
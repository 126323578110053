import React, { Component } from "react";
import Alert from "./Alert";

class AlertsList extends Component {

    render() {
        const alerts = this.props.alerts;

        return (

            <div className="data">
                <table className="ui celled table">
                    <thead>
                    <tr>
                        <th>Marka</th>
                        <th>Rok początkowy</th>
                        <th>Rok końcowy</th>
                        <th>Aktywność</th>
                    </tr>
                    </thead>

                    <tbody>
                    {alerts.map((alert) => {
                        return (
                            <Alert alert={alert} key={alert.id}/>
                        )
                    })}


                    </tbody>
                </table>
            </div>
        );
    }
}

export default AlertsList;
import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {} from 'react-router-dom';
import "./app.css";
import axios from "axios";



class PasswordChange extends Component {

    constructor() {
        super();
        this.state = {
            password: '',
            confirmedpassword: '',
        }
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        localStorage.setItem("token", urlParams.get('token'));
        localStorage.setItem("email", urlParams.get('email'));
    }


    handleInputPass = (e) => {    this.setState({
        password: e.target.value,

    });console.log(this.state.token);  }

    handleInputCPass = (e) => {    this.setState({
        confirmedpassword: e.target.value,
    }); }

        changePassword = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://api.uszkodzone24.pl/api/password/reset', {
            password: this.state.password,
            password_confirmation: this.state.confirmedpassword,
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token")

        }).then(() => {
            window.alert('Poprawnie zmieniono hasło');
        })

    }

    render() {
        return  <div className="ui middle aligned center aligned grid">
            <div className="column">
                <h2 className="ui image header">
                    <div className="content">
                    <img src={'https://uszkodzone24.pl/logo.png'} className={'img-fluid'} alt={'Uszkodzone logo'}/>
                        Zmiana hasła
                    </div>
                </h2>
                <form onSubmit={this.changePassword} className="ui large form">
                    <div className="ui stacked secondary  segment">
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="password" name="password" placeholder="Hasło" value={this.state.password} onChange={this.handleInputPass}/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="password" name="confirmedpassword" placeholder="Powtórz hasło" value={this.state.confirmedpassword} onChange={this.handleInputCPass}/>
                            </div>
                        </div>
                        <button className="ui fluid large teal submit button" style={{backgroundColor: '#EC4137'}} >Zmień hasło</button>
                    </div>

                    <div className="ui error message"></div>

                </form>
            </div>
        </div>
    }
}

export default PasswordChange;
import React, { Component } from "react";
import axios from "axios";



class Favorite extends Component {

    deleteFromFavorite = async (id) => {
        const res = await axios.get('https://api.uszkodzone24.pl/api/delete-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }
    render() {
        const { thumbnail_link,insurance,title,id,end_at } = this.props.offer;
        return (
            <tr>
                <td style={{textAlign: "center"}}><a href={'/aukcja/'+id}><img src={'https://api.licytacjezeszwajcarii.pl//storage/'+thumbnail_link} style={{width: "100px"}} alt=""/></a></td>
                <td>{insurance}</td>
                <td><a href={'/aukcja/'+id}>{title.slice(0, -14)}</a></td>
                <td>{end_at}</td>
                <td><button className="ui button" onClick={() => this.deleteFromFavorite(id)}>Usuń z ulubionych</button></td>

            </tr>
        );
    }

}

export default Favorite;
import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducers from "./../store/reducers";
import AddPage from "./AddPage";
import AddUser from "./AddUser";
import Administration from "./Administration";
import AdministrationSingle from "./AdministrationSingle";
import AgroCars from "./AgroCars";
import AgroCarsForQuests from "./AgroCarsForQuests";
import Alerts from "./Alerts";
import AuctionSingle from "./AuctionSingle";
import AuctionSingleForQuests from "./AuctionSingleForQuests";
import Auctions from "./Auctions";
import AuctionsForQuests from "./AuctionsForQuests";
import BlockUser from "./BlockUser";
import CookiesPolicy from "./CookiesPolicy";
import EditPage from "./EditPage";
import EditUser from "./EditUser";
import Login from "./Login";
import Motors from "./Motors";
import MotorsForQuests from "./MotorsForQuests";
import MyFavorites from "./MyFavorites";
import MyOffers from "./MyOffers";
import OtherCars from "./OtherCars";
import OtherCarsForQuests from "./OtherCarsForQuests";
import Page from "./Page";
import PageList from "./PageList";
import PasswordChange from "./PasswordChange";
import PasswordReset from "./PasswordReset";
import PersonalCars from "./PersonalCars";
import PersonalCarsForQuests from "./PersonalCarsForQuests";
import RegisterUser from "./RegisterUser";
import Trucs from "./Trucs";
import TrucsForQuests from "./TrucsForQuests";
import UserProfile from "./UserProfile";
import Users from "./Users";
import VanCars from "./VanCars";
import VanCarsForQuests from "./VanCarsForQuests";
import { ProtectedRoute } from "./common/router/protected";

const store = createStore(rootReducers, applyMiddleware(thunk));

const guestRoutes = [
    { path: "/", component: AuctionsForQuests, exact: true },
    { path: "/osobowe", component: PersonalCarsForQuests, exact: true },
    { path: "/motocykle", component: MotorsForQuests, exact: true },
    { path: "/dostawcze", component: VanCarsForQuests, exact: true },
    { path: "/ciezarowe", component: TrucsForQuests, exact: true },
    { path: "/rolnicze", component: AgroCarsForQuests, exact: true },
    { path: "/inne", component: OtherCarsForQuests, exact: true },
    { path: "/zaloguj", component: Login, exact: true },
    { path: "/przypomnij-haslo", component: PasswordReset, exact: true},
    { path: "/resetuj-haslo", component: PasswordChange, exact: true},
    { path: "/rejestracja", component: RegisterUser, exact: true},
    { path: "/aukcjazeszwajcarii/:id", component: AuctionSingleForQuests, exact: true },
    { path: "/polityka-prywatnosci", component: CookiesPolicy, exact: true },
    { path: "/strony", component: PageList, exact: true },
    { path: "/strona/:id", component: Page, exact: true },
    { path: "/edytuj-strone/:id", component: EditPage, exact: true },
    { path: "/dodaj-strone", component: AddPage, exact: true },

];

const protectedRoutes = [
    { path: "/lista-aukcji", component: Auctions, exact: true },
    { path: "/lista-osobowe", component: PersonalCars, exact: true },
    { path: "/lista-motocykle", component: Motors, exact: true },
    { path: "/lista-dostawcze", component: VanCars, exact: true },
    { path: "/lista-ciezarowe", component: Trucs, exact: true },
    { path: "/lista-rolnicze", component: AgroCars, exact: true },
    { path: "/lista-inne", component: OtherCars, exact: true },
    { path: "/aukcja/:id", component: AuctionSingle, exact: true },
    { path: "/moje-oferty", component: MyOffers, exact: true },
    { path: "/moje-alerty", component: Alerts, exact: true },
    { path: "/ulubione", component: MyFavorites, exact: true },
    { path: "/uzytkownicy", component: Users, exact: true },
    { path: "/profil", component: UserProfile, exact: true },
    { path: "/dodaj-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb", component: AddUser, exact: true },
    { path: "/zablokuj-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb", component: BlockUser, exact: true },
    { path: "/edytuj-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb", component: EditUser, exact: true },
    { path: "/administracja-snfhfhmngfdsbhjjhgfgfffdddxcvbbb", component: Administration, exact: true },
    { path: "/administracja-snfhfhmngfdsbhjjhgfgfffdddxcvbbb/:id", component: AdministrationSingle, exact: true },
];

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    {guestRoutes.map((route, key) => {
                        return (
                            <Route
                                exact={route.exact}
                                path={route.path}
                                component={route.component}
                                key={key}
                            />
                        );
                    })}
                    <Provider store={store}>
                        {protectedRoutes.map((route, key) => {
                            return (
                                <ProtectedRoute
                                    exact={route.exact}
                                    path={route.path}
                                    component={route.component}
                                    key={key}
                                />
                            );
                        })}
                    </Provider>
                    <Route component={Error} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
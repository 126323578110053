import React, { Component } from "react";
import axios from "axios";



class AuctionDetailsForQuests extends Component {

    constructor() {
        super();
        this.state = {
        }
    }



    render() {
        const {id, title, insurance,description_table} = this.props.auction;
        const imgArray = this.props.images.toString().split(',');
        const titleSliced = this.props.titleSliced;
        const end_atReplaced = this.props.end_atReplaced;
        console.log(insurance);
        return (
            <div>
            <h1> {titleSliced} {insurance} <br/> Data zakończenia: {end_atReplaced}</h1>
            <div dangerouslySetInnerHTML={{__html: description_table}}/>
                {imgArray.map((image) => {
                    return (
                        <img className="img-fluid" src={'https://api.licytacjezeszwajcarii.pl/storage/'+image} alt={image} key={image}/>
                    )
                })}
                
            </div>
        );
    }

}

export default AuctionDetailsForQuests;
import React, { Component } from "react";
import AuctionForQuests from "./AuctionForQuests";

class AuctionListForQuests extends Component {
    constructor() {
        super();
        this.state = {
            searchMark: '',
            searchStartDate: '',
            searchEndDate: ''
        }
    }

    updateSearchMark(event) {
        this.setState({searchMark: event.target.value.toLowerCase().substr(0,20)});
    }

    updateSearchStartDate(event) {
        this.setState({searchStartDate: event.target.value.toLowerCase().substr(0,20)});
    }

    updateSearchEndDate(event) {
        this.setState({searchEndDate: event.target.value.toLowerCase().substr(0,20)});
    }

    render() {
        const auctions = this.props.auctions;
        //console.log(favorites);
        let filteredAuctions = auctions.filter(
            (auction) =>{
                return auction.title.toUpperCase().indexOf(this.state.searchMark) !== -1 || auction.title.toLowerCase().indexOf(this.state.searchMark) !== -1 || auction.title.indexOf(this.state.searchMark) !== -1 ||
                auction.insurance.toUpperCase().indexOf(this.state.searchMark) !== -1 || auction.insurance.toLowerCase().indexOf(this.state.searchMark) !== -1 || auction.insurance.indexOf(this.state.searchMark) !== -1;
            }
        ).filter(
                (auction) =>{
                    return (parseInt(auction.register_date) >= parseInt(this.state.searchStartDate)) || this.state.searchStartDate == '';
                })
                .filter(
                    (auction) =>{
                        return (parseInt(auction.register_date) <= parseInt(this.state.searchEndDate)) || this.state.searchEndDate == '';
                    });
        return (

          <div className="data">
              <input type="text" className={"search-custom"} value={this.state.searchMark} onChange={this.updateSearchMark.bind(this)} placeholder="Szukaj"/>
              <input type="text" className={"search-custom-year margin-left"} value={this.state.searchStartDate} onChange={this.updateSearchStartDate.bind(this)} placeholder="Od roku"/>
              <input type="text" className={"search-custom-year margin-left"} value={this.state.searchEndDate} onChange={this.updateSearchEndDate.bind(this)} placeholder="Do roku"/>
                  <table className="ui celled table">
                  <thead>
                  <tr>
                      <th style={{width: "148px"}}></th>
                      <th>Ubezpieczalnia</th>
                      <th>Rok pierwszej rejestracji</th>
                      <th>Tytuł</th>
                      <th>Koniec aukcji</th>
                  </tr>
                  </thead>

                  <tbody id="table-with-auctions">
                  {filteredAuctions.map((auctions) => {
                      return (
                              <AuctionForQuests auctions={auctions} key={auctions.id}/>

                          )
                      })}
                  </tbody>
              </table>
          </div>
        );
    }
}

export default AuctionListForQuests;
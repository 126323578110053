import React, { Component } from "react";
import axios from "axios";



class AuctionForQuests extends Component {

     render() {
        const {id, thumbnail_link, title, insurance,end_at,images_content,register_date} = this.props.auctions;
         return (
            <tr>
                <td style={{textAlign: "center"}}><a href={'/aukcjazeszwajcarii/'+id}><img src={'https://api.licytacjezeszwajcarii.pl/storage/'+(images_content.toString().split(','))[0]} style={{width: "200px"}} alt=""/></a></td>
                <td>{insurance}</td>
                <td>{/^\d+$/.test(register_date) ? register_date : ''}</td>
                <td><a href={'/aukcjazeszwajcarii/'+id}>{title.slice(0, -14)}</a></td>
                <td>{end_at.replace('.000000Z','').replace('T',' ')}</td>
            </tr>
        );
    }

}

export default AuctionForQuests;
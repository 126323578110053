import React, { Component } from "react";
import axios from "axios";
import AuctionDetails from "./AuctionDetails";
import Loader from "./Loader";
import "./app.css";
import Menu from "./Menu";


class AuctionSingle extends Component {
    state = {
        auction: [],
        images: '',
        auctionsadmins: [],
        titleSliced: '',
        end_atReplaced: '',
        loader: false,
        url: "https://api.uszkodzone24.pl/api/auction/" + this.props.match.params.id,
        urladmin: "https://api.uszkodzone24.pl/api/administration/"+this.props.match.params.id,
        user: localStorage.getItem('user-email')
    }
    getAuction = async () => {
        this.setState({loader: true});
        const auction = await axios.get(this.state.url);
        this.setState({ auction: auction.data,images: auction.data.images_content, loader: false, titleSliced: auction.data.title, end_atReplaced: auction.data.end_at});
        const auctionsadmins = await axios.get(this.state.urladmin);
        this.setState({ auctionsadmins: auctionsadmins.data, loader: false});
    };


    componentDidMount() {
        this.getAuction();
    }

    render() {

        return <div>
            
                <div className="ui main container" style={{marginTop: '30px'}}>
                <div className="row justify-content-xl-end">
                {/* <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-10px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="#"><img src={'fb-icon.png'} className={'img-fluid'} alt={'Ikona FB'}/><span style={{paddingLeft: '10px'}}>Polub nas na Facebooku</span></a></div> */}
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-20px"}}><a href="#" style={{textDecoration: 'none', color: '#000', fontWeight: '600', marginTop: '6px'}}><span style={{paddingRight: '10px'}}>Kontakt:</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-40px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="tel:+48731057255"><img src={'https://uszkodzone24.pl/phone-icon.png'} className={'img-fluid'} alt={'Ikona telefonu'}/><span style={{paddingLeft: '10px'}}>0048 731 057 255 (9:00-16:00)</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end"><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="mailto:uszkodzone.info@gmail.com"><img src={'https://uszkodzone24.pl/mail-icon.png'} className={'img-fluid'} alt={'Ikona maila'}/><span style={{paddingLeft: '10px'}}>uszkodzone.info@gmail.com</span></a></div>
                </div>
                <Menu />
                <div style={{ maxWidth: '380px' }} className={'icons-div'}>
  <a href={'/lista-osobowe'}><img src={'https://uszkodzone24.pl/car.png'} style={{width: '40px'}} className={'img-fluid icon'} alt={'Osobowe'}/></a>
  <a href={'/lista-motocykle'}><img src={'https://uszkodzone24.pl/motocycle.png'} style={{width: '60px',paddingLeft: '20px'}} className={'img-fluid icon'} alt={'Motocykle'}/></a>
  <a href={'/lista-dostawcze'}><img src={'https://uszkodzone24.pl/dostawcze.png'} style={{width: '60px',paddingLeft: '20px'}} className={'img-fluid icon'} alt={'Dostawcze'}/></a>
  <a href={'/lista-ciezarowe'}><img src={'https://uszkodzone24.pl/ciezarowe.png'} style={{width: '90px',paddingLeft: '20px'}} className={'img-fluid icon ciez'} alt={'Ciężarowe'}/></a>
  {/* <img src={'https://uszkodzone24.pl/przyczepa.png'} style={{width: '55px',paddingLeft: '20px',marginBottom: '5px'}} className={'img-fluid icon'} alt={'Przyczepy'}/> */}
  <a href={'/lista-rolnicze'}><img src={'https://uszkodzone24.pl/rolnicze.png'} style={{width: '46px',paddingLeft: '20px'}} className={'img-fluid icon rol'} alt={'Rolnicze'}/></a>
  <div style={{float: 'right', marginTop: '18px'}} className={'other-div'}>
  <a href={'/lista-inne'}><p style={{paddingRight: '20px',display: 'inline', fontWeight: '600',fontSize: '24px',color: '#00000'}}>Inne</p></a>
  </div>
  </div>
                    {
                        this.state.loader ? <Loader /> : ""
                    }
                    <AuctionDetails auction={this.state.auction} auctionsadmins={this.state.auctionsadmins} images={this.state.images} titleSliced={this.state.titleSliced.slice(0, -14)} end_atReplaced={this.state.end_atReplaced.replace('.000000Z','').replace('T',' ')}/>
                </div>
            </div>
    }
}

export default AuctionSingle;
import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import AuthService from "./../services/AuthService";
import {} from 'react-router-dom';
import "./app.css";



class Login extends Component {
    state = {username: '', password: ''}
    async handleFormSubmit(event) {
        event.preventDefault();
        const postData = {username: this.state.username, password: this.state.password};
        console.log(postData.username);
        localStorage.setItem('user-email', postData.username);
        const response = await AuthService.doUserLogin(postData);
        if (response) {
            AuthService.handleLoginSuccess(response);
            //this.props.history.push('/lista-aukcji');
           // window.location.replace("https://uszkodzone24.pl/lista-aukcji");
            window.location.replace("/lista-aukcji");
        }
    }
    render() {
        const {username,password} = this.state;
        return  <div className="ui middle aligned center aligned grid">
            <div className="column">
                <h2 className="ui image header">
                    <div className="content">
                    <img src={'https://uszkodzone24.pl/logo.png'} className={'img-fluid'} alt={'Uszkodzone logo'}/>
                    </div>
                </h2>
                <form onSubmit={event => this.handleFormSubmit(event)} className="ui large form">
                    <div className="ui stacked secondary  segment">
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input type="text" name="email" placeholder="E-mail" value={username} onChange={event => this.setState({username: event.target.value})}/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="lock icon"></i>
                                <input type="password" name="password" placeholder="Hasło" value={password} onChange={event => this.setState({password: event.target.value})}/>
                                
                            </div>
                        </div>
                        <input type="checkbox" required name="agreement"></input>
                        <label for="agreement">Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z zasadami opisanymi w <a href="/polityka-prywatnosci">Polityce prywatności</a> oraz zaświadczam, że zapoznałem się z informacjami zawartymi w<a href="https://uszkodzone24.pl/strona/3"> Ważnej informacji handlowej</a>. Bez wyrażenia zgody logowanie/ rejestracja nie są możliwe.</label>
                        <button className="ui fluid large teal submit button" style={{backgroundColor: '#EC4137',marginTop: '20px'}} >Zaloguj</button>
                        <p style={{marginTop:'20px'}}><a href={'/rejestracja'}>Zarejestruj</a></p>
                    </div>

                </form>
                <div className="ui message"><a href={'/przypomnij-haslo'}>Przypomnij hasło</a></div>
                <div className="ui message"><a href={'/'}>Strona główna</a></div>
            </div>
        </div>
    }
}

export default Login;
import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {} from 'react-router-dom';
import "./app.css";
import axios from "axios";



class PasswordReset extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
        }
    }

    handleInput = (e) => {    this.setState({
        email: e.target.value,
    });  }

    SendNewPasswordRequest = async (e) => {
        e.preventDefault();
            const res = await axios.post('https://api.uszkodzone24.pl/api/password/forgot-password', {
                email: this.state.email
            }).then(() => {
                window.alert('Poprawnie wysłano link resetowania');
            })

    }

    render() {
        return  <div className="ui middle aligned center aligned grid">
            <div className="column">
                <h2 className="ui image header">
                    <div className="content">
                    <img src={'https://uszkodzone24.pl/logo.png'} className={'img-fluid'} alt={'Uszkodzone logo'}/>
                     Resetuj hasło
                    </div>
                </h2>
                <form onSubmit={this.SendNewPasswordRequest} className="ui large form">
                    <div className="ui stacked secondary  segment">
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="text" name="email" placeholder="E-mail" value={this.state.email} onChange={this.handleInput}/>
                            </div>
                        </div>
                        <button className="ui fluid large teal submit button" style={{backgroundColor: '#EC4137'}} >Wyślij link resetujący</button>
                    </div>

                    <div className="ui error message"></div>

                </form>
            </div>
        </div>
    }
}

export default PasswordReset;
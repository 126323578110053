import axios from "axios";
import React, { Component } from "react";



class Auction extends Component {

    state = {
        user: localStorage.getItem('user-email')
    }

    addFavorite = async (id) => {
        const res = await axios.get('https://api.uszkodzone24.pl/api/add-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }

    addFavorite = async (id) => {
        const res = await axios.get('https://api.uszkodzone24.pl/api/add-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }

    setCarType = async (id,type) => {
        const res = await axios.get('https://api.uszkodzone24.pl/api/set-type/'+type+'/'+id);
        window.location.reload(false);

    }

     render() {
        const {id, thumbnail_link, title, insurance,end_at,images_content,register_date} = this.props.auctions;
         const {auction_id} = this.props.favorites;
         const obj = this.props.favorites;
         return (
            <tr>
                {(obj.includes(this.props.auctions.id)) ? (
                    <td style={{textAlign: "center",cursor: "pointer"}} onClick={() => this.dellFavorite(id)}><i className="far fa-star" style={{color: "red"}}></i></td>
                    ) :

                    ( <td style={{textAlign: "center",cursor: "pointer"}} onClick={() => this.addFavorite(id)}><i className="far fa-star"></i></td> )
                                }
                <td style={{textAlign: "center"}}><a href={'/aukcja/'+id}><img src={'https://api.licytacjezeszwajcarii.pl/storage/'+(images_content.toString().split(','))[0]} style={{width: "200px"}} alt=""/></a></td>
                <td>{insurance}</td>
                <td>{/^\d+$/.test(register_date) ? register_date : ''}</td>
                <td><a href={'/aukcja/'+id}>{title.slice(0, -14)}</a></td>
                <td>{end_at.replace('.000000Z','').replace('T',' ')}</td>
                {(this.state.user === "norbert.skrzypczynski@wp.pl") ? (
                    <td>
                        <button style={{margin: "10px"}} onClick={() => this.setCarType(id,"osobowy")}>Osobowy</button>
                        <button style={{margin: "10px"}} onClick={() => this.setCarType(id,"motor")}>Motocykl</button>
                        <button style={{margin: "10px"}} onClick={() => this.setCarType(id,"dostawczy")}>Dostawczy</button>
                        <button style={{margin: "10px"}} onClick={() => this.setCarType(id,"ciezarowy")}>Ciężąrowy</button>
                        <button style={{margin: "10px"}} onClick={() => this.setCarType(id,"rolniczy")}>Rolniczy</button>
                        <button style={{margin: "10px"}} onClick={() => this.setCarType(id,"inny")}>Inny</button>
                    </td>) : (null)
                    }
            </tr>
        );
    }

}

export default Auction;
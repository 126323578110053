import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {} from 'react-router-dom';
import "./app.css";
import axios from "axios";



class RegisterUser extends Component {

    constructor() {
        super();
        this.state = {
            password: '',
            email: '',
            name: '',
            phone: '',
            user: localStorage.getItem('user-email')
        }
    }


    handleInputName = (e) => {    this.setState({
        name: e.target.value,

    });  }

    handleInputEmail = (e) => {    this.setState({
        email: e.target.value,
    }); }

    handleInputPass = (e) => {    this.setState({
        password: e.target.value,
    }); }

    handleInputPhone = (e) => {    this.setState({
        phone: e.target.value,
    }); }

    createUser = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://api.uszkodzone24.pl/api/register', {
            password: this.state.password,
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone

        }).then(() => {
            window.alert('Poprawnie zarejestrowano użytkownika');
            window.location.replace("https://uszkodzone24.pl");
        })

    }

    render() {
        return <div>
        <div className="ui middle aligned center aligned grid">
            <div className="column">
                <h2 className="ui image header">
                    <div className="content">
                        <img src={'https://uszkodzone24.pl/logo.png'} className={'img-fluid'} alt={'Uszkodzone logo'}/>
                        Zarejestruj się
                    </div>
                </h2>
                <form onSubmit={this.createUser} className="ui large form">
                    <div className="ui stacked secondary  segment">
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="text" name="name" placeholder="Nazwa" value={this.state.name} onChange={this.handleInputName}/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="text" name="email" placeholder="E-mail" value={this.state.email} onChange={this.handleInputEmail}/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="password" name="password" placeholder="Hasło" value={this.state.password} onChange={this.handleInputPass}/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input required type="text" name="phone" placeholder="Telefon (9 cyfr)" value={this.state.phone} onChange={this.handleInputPhone}/>
                            </div>
                        </div>
                        <input type="checkbox" required name="agreement"></input>
                        <label for="agreement">Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z zasadami opisanymi w <a href="/polityka-prywatnosci">Polityce prywatności</a>  oraz zaświadczam, że zapoznałem się z informacjami zawartymi w<a href="https://uszkodzone24.pl/strona/3"> Ważnej informacji handlowej</a>. Bez wyrażenia zgody logowanie/ rejestracja nie są możliwe.</label>
                        <button className="ui fluid large teal submit button" style={{backgroundColor: '#EC4137',marginTop: '20px'}} >Dodaj użytkownika</button>
                    </div>

                    <div className="ui error message"></div>

                </form>
            </div>
        </div>
        </div>

    }
}

export default RegisterUser;
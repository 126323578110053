import React, { Component } from "react";
import axios from "axios";


class OffersAdminList extends Component {

    constructor() {
        super();
        this.idsArray = [];
        this.id = '';
    }

    removeA(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax= arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }


        handleCheckbox = (e) => {
        const checked = e.target.checked;
        if (checked) {
            this.idsArray.push(e.target.value);
            console.log(this.idsArray);
        } else {
            this.removeA(this.idsArray,e.target.value);
            console.log(this.idsArray);
        }
    }

    deleteOffers = async (e) => {
        e.preventDefault();
            const res = await axios.post('https://api.uszkodzone24.pl/api/bulk-delete', {
                checked: this.idsArray,
            });
        window.location.reload(false);

        }
    deleteOffer = async (id) => {
        const res = await axios.get('https://api.uszkodzone24.pl/api/delete-offer/'+id);
        window.location.reload(false);

    }




    render() {
        const auctionsadmin = this.props.auctionsadmin;
        return (

            <div className="data">
                <table className="ui celled table">
                    <thead>
                    <tr>
                        <th style={{width: "50px"}}></th>
                        <th>Data</th>
                        <th>Akcja</th>
                        <th>Tytuł</th>
                    </tr>
                    </thead>

                    <tbody>

                    {auctionsadmin.map((auctionsadmin) => {
                        return (
                            <tr>

                                <td><input type="checkbox" value={auctionsadmin.auction_id} onChange={this.handleCheckbox}/></td>
                                <td>{auctionsadmin.created_at.split(".")[0].replace("T"," ")}</td>
                                <td><button className="ui button"  onClick={() => this.deleteOffer(auctionsadmin.auction_id)}>Usuń</button></td>
                                <td><a href={"/administracja-snfhfhmngfdsbhjjhgfgfffdddxcvbbb/"+auctionsadmin.auction_id}>{auctionsadmin.auction_title}</a></td>

                            </tr>
                        )
                    })}

                    </tbody>
                </table>
                    <button className="ui button" type="submit" onClick={this.deleteOffers}>Usuń zaznaczone</button>
            </div>
        );
    }
}

export default OffersAdminList;
import React, { Component } from "react";
import axios from "axios";
import AuctionListForQuests from "./AuctionListForQuests";
import Loader from "./Loader";
import MenuQuests from './MenuQuests';
import "./app.css";


class CookiesPolicy extends Component {


    render() {

        return <div>
            
                <div className="ui main container" style={{marginTop: '30px'}}>
                <div className="row justify-content-xl-end">
                {/* <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-10px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="#"><img src={'fb-icon.png'} className={'img-fluid'} alt={'Ikona FB'}/><span style={{paddingLeft: '10px'}}>Polub nas na Facebooku</span></a></div> */}
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-20px"}}><a href="#" style={{textDecoration: 'none', color: '#000', fontWeight: '600',marginTop: '6px'}}><span style={{paddingRight: '10px'}}>Kontakt:</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end" style={{marginRight: "-40px"}}><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="tel:+48731057255"><img src={'phone-icon.png'} className={'img-fluid'} alt={'Ikona telefonu'}/><span style={{paddingLeft: '10px'}}>0048 731 057 255 (9:00-16:00)</span></a></div>
                <div className="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end"><a style={{textDecoration: 'none', color: '#000', fontWeight: '600'}} href="mailto:uszkodzone.info@gmail.com"><img src={'mail-icon.png'} className={'img-fluid'} alt={'Ikona maila'}/><span style={{paddingLeft: '10px'}}>uszkodzone.info@gmail.com</span></a></div>
                </div>
                <MenuQuests />
                <div style={{ maxWidth: '380px' }} className={'icons-div'}>
  <a href={'/osobowe'}><img src={'https://uszkodzone24.pl/car.png'} style={{width: '40px'}} className={'img-fluid icon'} alt={'Osobowe'}/></a>
  <a href={'/motocykle'}><img src={'https://uszkodzone24.pl/motocycle.png'} style={{width: '60px',paddingLeft: '20px'}} className={'img-fluid icon'} alt={'Motocykle'}/></a>
  <a href={'/dostawcze'}><img src={'https://uszkodzone24.pl/dostawcze.png'} style={{width: '60px',paddingLeft: '20px'}} className={'img-fluid icon'} alt={'Dostawcze'}/></a>
  <a href={'/ciezarowe'}><img src={'https://uszkodzone24.pl/ciezarowe.png'} style={{width: '90px',paddingLeft: '20px'}} className={'img-fluid icon ciez'} alt={'Ciężarowe'}/></a>
  {/* <img src={'https://uszkodzone24.pl/przyczepa.png'} style={{width: '55px',paddingLeft: '20px',marginBottom: '5px'}} className={'img-fluid icon'} alt={'Przyczepy'}/> */}
  <a href={'/rolnicze'}><img src={'https://uszkodzone24.pl/rolnicze.png'} style={{width: '46px',paddingLeft: '20px'}} className={'img-fluid icon rol'} alt={'Rolnicze'}/></a>
  <div style={{float: 'right', marginTop: '18px'}} className={'other-div'}>
  <a href={'/inne'}><p style={{paddingRight: '20px',display: 'inline', fontWeight: '600',fontSize: '24px',color: '#000000'}}>Inne</p></a>
  </div>
  </div>
                    <h1>I. DEFINICJE</h1>
                    <p>Ilekroć w Polityce Prywatności występują poniższe pojęcia, należy je rozumieć w następujący sposób:</p>
                    <ol>
                        <li>Awaria – oznacza stan Usługi, który uniemożliwia Użytkownikowi korzystanie z Usługi, jednak z wyłączeniem sytuacji, w której brak możliwości korzystania z Usługi jest wynikiem okresu zawieszenia lub przerwy w świadczeniu Usługi dozwolonej w ramach Polityki Prywatności; lub jest wynikiem naruszenia Polityki Prywatności przez Użytkownika; lub jest wynikiem działania Siły Wyższej; lub która wystąpiła w infrastrukturze telekomunikacyjnej, z której korzysta Użytkownik.</li>
                        <li>Polityka Prywatności – niniejszy dokument.</li>
                        <li>Serwis – serwis internetowy, który umożliwia Użytkownikom przechowywanie Treści, pod adresem www.uszkodzone24.pl za pośrednictwem którego świadczona jest Usługa.</li>
                        <li>Strony – Usługodawca, Użytkownik.</li>
                        <li>Siła Wyższa – zdarzenie nadzwyczajne o charakterze zewnętrznym wobec Strony, niezależne od danej Strony, któremu Strona nie mogła zapobiec przy dołożeniu należytej staranności.</li>
                        <li>Treść (Treści) – treści i obiekty multimedialne (np. informacje, dane, pliki graficzne, zdjęcia) w tym utwory w rozumieniu Ustawy o prawie autorskim i prawach pokrewnych oraz wizerunki osób fizycznych jakie są zamieszczane przez Użytkownika lub Usługodawcę.</li>
                        <li>Usługa – usługa hostingu świadczona przez Usługodawcę na zasadach określonych w Polityce Prywatności oraz za pośrednictwem Serwisu, która polega na zapewnieniu Użytkownikowi narzędzi teleinformatycznych celem przechowywania zamieszczonych przez Użytkownika.</li>
                        <li>Usługodawca – EUROCAP sp. z o.o., ul. Józefa Mackiewicza 1,3, 20-865 Lublin
                        <p>KRS: 0000671796, NIP: 7123336764, REGON: 366934420. Dane do kontaktu: uszkodzone.info@gmail.com</p></li>
                        <li>Użytkownik – osoba fizyczna, która ukończyła 18. roku życia.</li>
                        <li>Strona WWW – przestrzeń dostępna pod adresem www.uszkodzone24.pl</li>
                    </ol>
                    <h1>II. POSTANOWIENIA OGÓLNE</h1>
                    <ol>
                        <li>Użytkownicy zobowiązani są do przestrzegania postanowień niniejszej Polityki Prywatności.</li>
                        <li>Nazwa Serwisu, jego koncepcja, wygląd graficzny, oprogramowanie oraz baza danych, a także elementy graficzne Stron, które zostały udostępnione Użytkownikom przez Serwis podlegają ochronie prawnej.</li>
                        <li>Korzystanie z Serwisu oraz z usług oferowanych za pośrednictwem Serwisu oznacza wyrażenie przez Użytkownika zgody na warunki określone w niniejszej Polityce Prywatności.</li>
                        <li>Zgodnie z obowiązującymi przepisami prawa Usługodawca zastrzega sobie możliwość ograniczenia świadczenia Usługi za pośrednictwem Serwisu do osób, które ukończyły wiek 18 lat.</li>
                        <li>Użytkownicy ponoszą całkowitą odpowiedzialność za pisane przez siebie Treści.</li>
                        <li>Pewna część treści zawartych na łamach serwisu może wyświetlać się niepoprawnie w przypadku przeglądarek internetowych, które były długi czas nieaktualizowane. Najczęściej są to przypadki dotyczące wersji niewspieranych przez producentów. Z tego też powodu pewne opcje serwisu mogą nie działać poprawnie.</li>
                        <li>Zastrzegamy sobie prawo do wyłączenia Serwisu w całości lub części bez podawania przyczyn oraz do wprowadzania zmian w regulaminie funkcjonowania Serwisu.</li>
                    </ol>
                    <h1>III. POLITYKA PLIKÓW "COOKIES"</h1>
                    <ol>
                        <li> „Cookies" to pliki tekstowe, dane informatyczne przechowywane w urządzeniach Użytkowników. Pozwalają m.in. zidentyfikować rodzaj urządzenia Użytkownika i wyświetlać strony internetowe dostosowane do indywidualnych preferencji. Pliki „Cookies" zawierają najczęściej adres strony internetowej, czas przechowywania na urządzeniu Użytkownika oraz własny unikatowy identyfikator.</li>
                        <li>„Cookies" używamy w celu optymalizacji korzystania ze stron internetowych. Używane są również w celu gromadzenia danych statystycznych, które pozwalają identyfikować sposób korzystania Użytkowników ze stron internetowych. Daje to możliwość późniejszego ulepszania struktury i zawartości Strony WWW, z wyłączeniem personalnej identyfikacji Użytkownika. Dane osobowe gromadzone przy użyciu plików „cookies" mogą być zbierane wyłącznie w celu wykonywania określonych funkcji i aktywności na rzecz Użytkownika. Takie dane są zaszyfrowane w sposób uniemożliwiający dostęp do nich osobom nieuprawnionym.</li>
                        <li>Oprogramowanie wykorzystywane do przeglądania Strony WWW domyślnie dopuszcza umieszczanie plików „cookies" na urządzeniu końcowym. Użytkownik może dokonać właściwej konfiguracji przeglądarki, aby zablokować automatyczne akceptowanie plików „cookies" lub uzyskać każdorazową informację o przesłaniu pliku na urządzenie Użytkownika. Właściwe informacje o obsłudze plików „cookies" oraz możliwych konfiguracjach dostępne są w ustawieniach przeglądarki. Poziom ograniczeń dotyczący stosowania plików „cookies" może wpłynąć na dostępność i funkcjonalności oferowane na stronie internetowej, aż do zablokowania możliwości jej prawidłowego użytkowania.</li>
                    </ol>
                    <h1>IV. KOPIA BEZPIECZEŃSTWA</h1>
                    <ol>
                        <li>Dane na serwerach mydevil.net (właśnie u tego hostingodawcy są wykupione serwery Strony WWW) podlegają cyklicznej archiwizacji (tzw. backup). W szczególności dostępne są kopie zapasowe serwisów oraz poczty elektronicznej z ostatnich trzech dni. Umożliwia to szybkie odtworzenie zmodyfikowanego lub skasowanego zbioru.</li>
                        <li>Pamiętaj, że archiwizacja prowadzona jest w sposób punktowy – o określonych porach. Jeżeli list pojawił się na naszym serwerze pomiędzy dwoma sesjami backupu i został z niego usunięty (np. przez pobierający go program pocztowy) – jego odzyskanie będzie niemożliwe.</li>
                    </ol>
                    <h1>V. ZABEZPIECZENIA</h1>
                    <ol>
                        <li>Na serwerach mydevil.net funkcjonują zaawansowane i stale rozbudowywane filtry antyspamowe. Umożliwiają one filtrowanie niezamawianej poczty po adresie e-mail, serwerze i kraju pochodzenia, ale także – analizują temat i treść wiadomości pod kątem obecności tam charakterystycznych dla spamu słów i zwrotów. Każdy użytkownik może także samodzielnie tworzyć własne listy adresów, domen, IP lub hostów, z których nie życzy sobie otrzymywać poczty (tzw. czarna lista) lub też – poczta ma być doręczana (biała lista).</li>
                    </ol>
                    <h1>VI. PRZETWARZANIE DANYCH OSOBOWYCH</h1>
                    <ol>
                        <li>Administratorem danych osobowych podanych przez Użytkowników w rozumieniu przepisów ustawy z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz. U. z 2002 r., Nr 101, poz. 926 ze zm.) EUROCAP sp. z o.o., ul. Józefa Mackiewicza 1,3, 20-865 Lublin; KRS: 0000671796; NIP: 7123336764; REGON: 366934420.</li>
                        <li>Dane osobowe przetwarzane są przez Usługodawcę w celu świadczenia Usług.</li>
                    </ol>
                    <h1>VII. POUFNOŚĆ DANYCH</h1>
                    <ol>
                        <li>Korzystając ze Strony WWW możesz być pewien, że Twoje dane osobowe pozostaną bezpieczne.</li>
                        <li>Na żądanie uprawnionych organów (Prokuratura, Policja) każdy podmiot gospodarczy ma obowiązek udostępnienia posiadanych informacji.</li>
                        <li>Usługodawca pobiera dane techniczne Użytkownika, w szczególności takie jak: używany protokół internetowy, IP urządzenia, system operacyjny, typ przeglądarki, liczba odwiedzin witryny oraz adresy odwiedzanych stron internetowych. Dodatkowo możemy przetwarzać dane eksploatacyjne lub informacje o lokalizacji urządzenia Użytkownika za pomocą, którego następuje dostęp do Usługi. Użytkownika wyraża na rzecz Usługodawcy zgodę na dostęp do danych przechowywanych na serwerze, podczas wykonywania czynności technicznych i administracyjnych na serwerze przez Spółkę, w tym powierza przetwarzanie danych osobowych zgromadzonych w zbiorze danych przechowywanym na dysku serwera, o ile do przechowywania takiego zbioru danych dojdzie wyłącznie w zakresie niezbędnym do wykonania Usługi przez Usługodawcę w szczególności w zakresie kopiowania zbioru danych oraz zbioru danych i innych danych po zakończeniu świadczenia Usługi.</li>
                    </ol>
                    <h1>VIII. KOMENTARZE</h1>
                    <ol>
                        <li>Pod artykułami i wpisami można umieszczać komentarze, które są miejscem wymiany informacji, komentarzy na tematy związane z treściami Strony WWW.</li>
                        <li>Jako Usługodawca nie ponosimy odpowiedzialności za treści zamieszczane przez Użytkowników, ale zastrzegamy sobie prawo do usunięcia wypowiedzi naruszających postanowienia Polityki Prywatności, w tym w szczególności wypowiedzi zawierających treści rasistowskie, wzywające do nienawiści religijnej i etnicznej, seksistowskie, pornograficzne, urągające godności człowieka, wulgarne, urażające uczucia religijne oraz propagujących przemoc.</li>
                        <li>W komentarzach niedopuszczalne jest umieszczanie przez Użytkowników treści zawierających przekazy promocyjne (reklamowe).</li>
                        <li>Użytkownik, wypowiadając się w komentarzach, wyraża zgodę na umieszczenie jego wypowiedzi lub jej fragmentów w innych publikacjach Usługodawcy.</li>
                        <li>Administratorzy i moderatorzy pilnują, aby Polityka Prywatności była przestrzegana w komentarzach.</li>
                    </ol>
                    <h1>IX. PRAWA AUTORSKIE</h1>
                    <ol>
                        <li>Kopiowanie, reprodukowanie treści oraz wszelkich elementów graficznych z Serwisu w całości lub w części bez wcześniejszej pisemnej zgody Usługodawcy jest zabronione.</li>
                        <li>Redakcja serwisu publikując jakiekolwiek materiały w Serwisie opiera się na własnych treściach lub informacjach zamieszczonych w innych źródłach. W drugim przypadku zawsze podaje ich nazwy wraz z odpowiednimi linkami.</li>
                        <li>Użytkownik, umieszczając treści na łamach Serwisu wyraża zgodę na umieszczenie jego wypowiedzi lub jej fragmentów w innych publikacjach Usługodawcy oraz publikacjach firm, które współpracują z Usługodawcą.</li>
                        <li>Użytkownik rejestrując się na wizytę wyraża zgodę na wykorzystywanie jego danych osobowych przez Usługodawcę.</li>
                    </ol>
                    <h1>X. POSTANOWIENIA KOŃCOWE</h1>
                    <ol style={{marginBottom: "200px"}}>
                        <li>Usługodawca ma prawo odstąpić od świadczenia Usługi w przypadku naruszenia przez Użytkownika zapisów Polityki Prywatności, w szczególności zamieszczenia Treści o charakterze niedozwolonym czyli sprzecznych z przepisami obowiązującego prawa.</li>
                        <li>Uznanie poszczególnych postanowień niniejszej Polityki Prywatności w sposób przewidziany prawem za nieważne bądź nieskuteczne, nie wpływa na ważność czy skuteczność pozostałych postanowień Polityki Prywatności. W miejsce nieważnego postanowienia zastosowana będzie reguła, która jest najbliższa celom nieważnego postanowienia i całej Polityki Prywatności.</li>
                        <li>Sądem właściwym dla rozpatrywania sporów pomiędzy Usługodawcą, a Użytkownikiem jest sąd właściwy dla siedziby Usługodawcy.</li>
                        <li>Usługodawca zastrzega sobie prawo do zmiany niniejszej Polityki Prywatności w dowolnym czasie bez obowiązku uprzedniego powiadamiania Użytkowników. Zmiany obowiązują od momentu umieszczenia ich na stronie internetowej Serwisu. Zmiany uważane są za zaakceptowane przez Użytkownika z momentem skorzystania przez niego z Serwisu.</li>
                        <li>Polityka Prywatności obowiązuje od 06.10.2022 roku.</li>
                    </ol>
                </div>
            </div>
    }
}

export default CookiesPolicy;